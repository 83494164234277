export default{
        data() {
            return {
              fields: [
                { key: "title", label: "العنوان" },
                {key:"image",label:"الصورة"},
                { key: "actions", label: "" }
              ],
              estatesFormSchema: {
                title: {
                  component: "FormInput",
                  key: "title",
                  attrs: {
                    id: "title",
                    label: "العنوان",
                    rules: "required",
                  },
                },
                image: {
                  key: "image",
                },
              }
            };
          },
    
}